@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

nav.header-outer-container {
    background-color: rgba(0,0,0,0);
    color: black;
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
}

nav.header-outer-container div.header-container {
    padding: 40px 10px 40px 10px;
    text-align: center;
}

@media(min-width: 767px) {
    nav.header-outer-container div.header-container {
        padding: 50px;
    }
}

nav.header-outer-container i.header-bars {
    font-family: sans-serif, 'FontAwesome'!important;
    font-style: normal;
    color: black;
    font-size: 25px;
}

nav.header-outer-container button:active, nav.header-outer-container button:focus {
    outline: 0;
}

nav.header-outer-container div.header-links {
    justify-content: center;
}

nav.header-outer-container div.header-links .header-link {
    position: relative;
    width: 100%;
    margin: 0 20px;
    font-size: 25px;
    font-weight: 100;
    color: black;
    font-family: 'Raleway', sans-serif;
}

nav.header-outer-container div.header-links .header-link:active,
nav.header-outer-container div.header-links .header-link:focus {
    color: black;
    outline: 0;
    text-decoration: none;
}

nav.header-outer-container div.header-links .header-link:hover {
    color: #3e8c06;
    outline: 0;
    text-decoration: none;
}

@media (max-width: 767px) {

    nav.header-outer-container div.header-links .header-link {
        margin-top: 10px;
        width: 25%;
    }

}

@media (min-width: 768px) {

    nav.header-outer-container div.header-links .header-link {
        width: auto;
        min-width: 0;
    }

    nav.header-outer-container div.header-links .header-link {
        padding-bottom: 5px;
        border-bottom: 1px solid black;
    }

    nav.header-outer-container div.header-links .header-link.left {
        padding-left: 20px;
    }

    nav.header-outer-container div.header-links .header-link.right {
        padding-right: 20px;
    }

}
