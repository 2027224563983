@import url("https://fonts.googleapis.com/css?family=Oswald");
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

div.about-outer-container {
    padding: 10px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    color: rgba(0,0,0,0.75);
}

div.about-outer-container div.about-container {
    padding: 0;
}

div.about-outer-container div.about-container div.about-text {
    text-indent: 5%;
    padding: 0 5px;
}

div.about-outer-container hr {
    margin-bottom: 5px;
}

div.about-outer-container div.about-container span.about-header {
    font-weight: 700;
    font-size: 35px;
}

@media (max-width: 767px) {
    div.about-outer-container {
        font-size: 18px;
    }

    div.about-outer-container div.about-container span.about-header {
        font-size: 24px;
    }
}
