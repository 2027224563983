@import url("https://fonts.googleapis.com/css?family=Oswald");

div.contact-outer-container {
    padding: 50px 0;
    text-align: center;
    height: 100vh;
}

div.contact-outer-container div.contact-container {

}

div.contact-outer-container div.contact-container img {
    width: auto;
}

div.contact-outer-container div.contact-container div.contact-explicit-details {

}
