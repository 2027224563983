@import url('https://fonts.googleapis.com/css?family=Bowlby+One+SC&display=swap');


div.typing-outer-container {
    width: 40%;
    text-align: center;
}

div.typing-outer-container div.typing-container {

}

div.typing-outer-container div.typing-container div.typing-text {
    padding-top: 75px;
    padding-left: 15px;
    font-size: 5vw;
    color: rgba(0,0,0,0.75);
    font-family: 'Bowlby One SC', cursive;
    font-weight: bold;
}

@media (min-width: 768px) {
    div.typing-outer-container div.typing-container div.typing-text {
        font-size: 3vw;
        padding-left: 175px;
    }
}
