@import url("https://fonts.googleapis.com/css?family=Oswald");

div.footer-outer-container {
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    height: 20px;
    font-size: 10px;
    text-align: center;
    width: 100%;
    color: #3e8c06;
    font-family: 'Oswald', sans-serif;
}
