@import url('https://fonts.googleapis.com/css?family=Bowlby+One+SC&display=swap');

div.experience-outer-container {
    padding-top: 50px;
    padding-bottom: 20px;
}

div.experience-outer-container div.experience-container {

}

div.experience-outer-container div.experience-container .work-experience-project-container {
    margin-bottom: 50px;
}

div.experience-outer-container div.experience-container hr {
    margin-top: 4px;
    margin-bottom: 4px;
    background-color: black;
}

div.experience-outer-container div.experience-container .typing-text {
    margin-top: 50px;
    font-size: 36px;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-align: center;
}

@media (min-width: 768px) {
    div.experience-outer-container div.experience-container hr {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    div.experience-outer-container div.experience-container .typing-text {
        font-size: 45px;
    }
}
